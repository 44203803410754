import {
  APIContact,
  CheckType,
  Contact,
  ContactGroup,
  ContactUnit,
  createContactReference,
  Feature,
  isAllowed,
  PhoneNumber,
  ResourceType,
  Action,
} from '@super-software-inc/foundation'
import {
  AccessLevel,
  accessLevelLabels,
} from '@super-software-inc/foundation/dist/types/ACL'
import { updateContact } from 'api/contacts'
import FirebaseUserAvatar from 'components/app/FirebaseUserAvatar'
import {
  Divider,
  FlexRow,
  PrimaryButton,
  Select,
  TextButton,
  TextInput,
} from 'components/lib'
import { toastError, toastSuccess } from 'components/lib/Toast'
import { format } from 'date-fns'
import { HttpsCallable, httpsCallable } from 'firebase/functions'
import { ref, uploadBytes } from 'firebase/storage'
import { ErrorMessage, Field, Form, Formik } from 'formik'
import { contactsCacheAtom } from 'hooks/useContactsCache'
import _ from 'lodash'
import phone from 'phone'
import React, { forwardRef, useMemo, useState } from 'react'
import Dropzone from 'react-dropzone'
import { useFunctions, useStorage } from 'reactfire'
import { useRecoilState, useRecoilValue } from 'recoil'
import { authenticatedUserAtom, profileModalAtom } from 'state/atoms'
import styled from 'styled-components/macro'
import createUniqueFilename from 'utils/createUniqueFilename'
import { toJSDate } from 'utils/date'
import usStateAbbreviations from 'utils/usStateAbbreviations'
import * as Yup from 'yup'
import {
  primaryAssociationSelector,
  windowDimensionsAtom,
} from '../../../AppRoutes'
import FileManager from '../FileManager'
import EmailSection from './EmailSection'
import PhoneSection from './PhoneSection'
import UnitsSection from './UnitsSection'

export const ExplanatoryText = styled.div`
  font-size: 14px;
  color: ${props => props.theme.colors.text250};
  margin: 8px 0 24px;
`

export const FieldWithError = styled.div<{ marginRight?: boolean }>`
  width: 100%;
  margin-right: ${props => (props.marginRight ? '8px' : '0')};
`

export const ErrorText = styled.div`
  font-size: 12px;
  color: ${props => props.theme.colors.destructive};
  margin-bottom: 8px;
`

const ProfileDropZone = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  cursor: pointer;
  border: 1px solid ${props => props.theme.colors.bg300};
  padding: 20px;
  color: ${props => props.theme.colors.text300};
`

export const InputGroup = styled.div`
  margin-bottom: 24px;
  font-size: 14px;
`

export const InputGroupLabel = styled.span`
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 700;
  line-height: 20px;
  margin-bottom: 8px;
`

export const DatePickerInput = forwardRef<
  HTMLInputElement,
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >
>(({ value, onClick, style }, elRef) => (
  <div style={style}>
    <TextInput
      value={value ? format(toJSDate(value as string), 'MM/dd/yyyy') : ''}
      ref={elRef}
      onClick={onClick}
      placeholder="mm/dd/yyyy"
    />
  </div>
))

// this file can be opened in the CRM for 1 contact with 1 association,
// or it can be opened from settings, and include all associations for the authenticatedUser

const ProfileForm = ({
  contact,
  onRequestClose,
  isSettingsPage,
}: {
  contact: APIContact
  onRequestClose: Function
  isSettingsPage?: boolean
}) => {
  const storage = useStorage()
  const functions = useFunctions()
  const fileManagerPath = `companies/${contact.companyId}/companyContactFiles/${contact.id}/relevantFiles`

  functions.region = 'us-east1'

  const createContact: HttpsCallable<
    { companyId: string; contact: Partial<Contact> },
    APIContact
  > = httpsCallable(functions, 'createContact')

  const [authenticatedUser, setAuthenticatedUser] = useRecoilState(
    authenticatedUserAtom,
  )
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)

  const modifiedBy = useMemo(
    () =>
      createContactReference(
        authenticatedUser.selectedContact,
        primaryAssociation.id,
      ),

    [authenticatedUser.selectedContact, primaryAssociation.id],
  )

  const isResident = [
    ContactGroup.Owners,
    ContactGroup.Renters,
    ContactGroup.Residents,
    ContactGroup.PastOwners,
    ContactGroup.PastRenters,
    ContactGroup.PastResidents,
  ].some(group =>
    contact.propertyInfo
      .find(p => p.associationId === primaryAssociation.id)
      ?.groups?.includes(group),
  )

  const isVendorOrStaff = [ContactGroup.Vendors, ContactGroup.Staff].some(
    group =>
      contact.propertyInfo
        .find(p => p.associationId === primaryAssociation.id)
        ?.groups?.includes(group),
  )

  const [formError, setFormError] = useState('')
  const [photoURL, setPhotoURL] = useState(contact?.photoURL)

  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)
  const windowDimensions = useRecoilValue(windowDimensionsAtom)
  const contactsCache = useRecoilValue(contactsCacheAtom)

  const actChoices = [
    {
      label: accessLevelLabels[AccessLevel.NoAccess],
      value: authenticatedUser.acts.find(
        act => act.name === AccessLevel.NoAccess,
      )?.id,
    },
    {
      label: accessLevelLabels[AccessLevel.StandardAccess],
      value: authenticatedUser.acts.find(
        act => act.name === AccessLevel.StandardAccess,
      )?.id,
    },
    {
      label: accessLevelLabels[AccessLevel.FullAccess],
      value: authenticatedUser.acts.find(
        act => act.name === AccessLevel.FullAccess,
      )?.id,
    },
  ]

  const validateEmail = (
    emailToValidate: string,
    isPrimaryEmail: boolean,
    formData: APIContact,
  ) => {
    // primary email is allowed to be blank
    if (
      !emailToValidate ||
      (emailToValidate?.length === 0 && isPrimaryEmail === true)
    ) {
      return {
        status: true,
        message: '',
      }
    }

    const normalizedEmailToValidate = emailToValidate?.trim().toLowerCase()

    // validate it's a proper email address
    if (
      !normalizedEmailToValidate
        .trim()
        .toLowerCase()
        .match(/^[\w-+.]+@([\w-]+\.)+[\w-]{2,4}$/)
    ) {
      return {
        status: false,
        message: 'Invalid email.',
      }
    }
    // todo - validate it's not another email already in the form

    // validate the email doesn't already exist on another contact
    // TODO: should this validate against all contacts in the company, not just
    // the association?
    const emailExists = contactsCache.find((c: APIContact) => {
      if (c.id === formData.id) {
        return false
      }

      return (
        c?.email === normalizedEmailToValidate ||
        c.secondaryEmails?.includes(normalizedEmailToValidate)
      )
    })

    if (emailExists) {
      return {
        status: false,
        message: "There's already an existing contact with this email.",
      }
    }

    // if the secondary email is filled out, and the primary email is empty
    if (
      !isPrimaryEmail &&
      (formData.email === undefined || formData.email.length === 0)
    ) {
      return {
        status: false,
        message:
          'Please make this the primary email, or remove the email fom this contact.',
      }
    }

    return {
      status: true,
      message: '',
    }
  }

  const validatePhone = (
    phoneToValidate: PhoneNumber,
    isPrimaryPhone: boolean,
    formData: APIContact,
  ) => {
    const { number, type } = phoneToValidate

    // primary phone is allowed to be blank
    if (!number || (number.length === 0 && isPrimaryPhone === true)) {
      return {
        status: true,
        message: '',
      }
    }

    const result = phone(number)

    if (!result.isValid) {
      return {
        status: false,
        message: 'Invalid phone number.',
      }
    }

    // validate it's the correct length
    if (number.length < 10) {
      return {
        status: false,
        message: 'Phone number is invalid.',
      }
    }
    // // todo - validate it's not another phone already in the form

    // validate the phone doesn't already exist on another contact if it's mobile
    // some stored phones start with a plus, or a 1 for the area code.
    // if the number is mobile, make sure new number is not some variation of that.
    if (type === 'mobile') {
      const phoneExists = contactsCache.find(
        (associationContact: APIContact) => {
          const secondaryMobilePhoneNumbers = associationContact.secondaryPhones
            ?.filter(p => p.type === 'mobile')
            .map(p => p.number)

          return (
            associationContact.id !== formData.id &&
            ((associationContact.phone &&
              associationContact.phone.type === 'mobile' &&
              associationContact.phone.number.slice(-10) ===
                number.slice(-10)) ||
              secondaryMobilePhoneNumbers?.includes(phoneToValidate.number) ||
              secondaryMobilePhoneNumbers?.includes(
                `+${phoneToValidate.number}`,
              ) ||
              secondaryMobilePhoneNumbers?.includes(
                `+1${phoneToValidate.number}`,
              ))
          )
        },
      )

      if (phoneExists) {
        return {
          status: false,
          message:
            'There’s already an existing contact with this phone number. ',
        }
      }
    }
    // if the secondary email is filled out, and the primary email is empty
    if (!isPrimaryPhone && formData.phone?.number.length === 0) {
      return {
        status: false,
        message:
          'Please make this the primary phone, or remove the phone fom this contact.',
      }
    }

    return {
      status: true,
      message: '',
    }
  }

  const ContactSchema = useMemo(
    () =>
      Yup.object({
        firstName: Yup.string(),
        lastName: Yup.string(),
        email: Yup.string(),
        secondaryEmails: Yup.array().of(Yup.string().email()).nullable(),
        phone: Yup.object({
          type: Yup.string(),
          number: Yup.string(),
        }),
        secondaryPhones: Yup.array()
          .of(
            Yup.object({
              type: Yup.string(),
              number: Yup.string(),
            }),
          )
          .nullable(),
        businessName: Yup.string(),
        ein: Yup.string(),
        website: Yup.string(),
        emergencyContact: Yup.object({
          firstName: Yup.string(),
          lastName: Yup.string(),
          relationship: Yup.string(),
          email: Yup.string().email(),
          phone: Yup.string(),
        }),
        mailingAddress: Yup.object({
          line1: Yup.string(),
          line2: Yup.string(),
          city: Yup.string(),
          state: Yup.string(),
          zip: Yup.string(),
        }),
        propertyInfo: Yup.array().of(
          Yup.object({
            groups: Yup.array().of(Yup.string()),
            actId: Yup.string(),
            accessLevel: Yup.string(),
            units: Yup.array().of(
              Yup.object({
                unitId: Yup.string(),
                name: Yup.string(),
                relationship: Yup.string(),
                createdBy: Yup.string().nullable(),
                startDate: Yup.string().nullable(),
                endDate: Yup.string().nullable(),
              }),
            ),
          }),
        ),
      }),
    [],
  )

  return (
    <>
      {contact.id && (
        <Dropzone
          multiple={false}
          accept={'image/*'}
          maxFiles={1}
          maxSize={25000000}
          onDropRejected={rejectedFiles => {
            const errorCode = rejectedFiles[0]?.errors[0]?.code

            if (errorCode === 'file-too-large') {
              toastError('File is larger than 25MB. Please try a smaller file.')
            }
          }}
          onDropAccepted={async acceptedFiles => {
            const file = acceptedFiles[0]
            const fileName = createUniqueFilename(file.name)

            const fileWithUniqueName = new File([file], fileName)

            const fileRef = ref(
              storage,
              `${contact.userId}/profilePicture/${fileWithUniqueName.name}`,
            )

            const uploadedFile = await uploadBytes(
              fileRef,
              fileWithUniqueName,
              {
                contentType: fileWithUniqueName.type,
              },
            )

            await updateContact({
              ...contact,
              photoURL: uploadedFile.ref.fullPath,
            })

            setPhotoURL(uploadedFile.ref.fullPath)
          }}
        >
          {({ getRootProps, getInputProps }) => (
            <section>
              <ProfileDropZone
                {...getRootProps()}
                style={{ marginBottom: 32, paddingRight: 12, maxWidth: 600 }}
              >
                <input {...getInputProps()} />
                <FirebaseUserAvatar
                  key={photoURL}
                  size={64}
                  photoURL={photoURL}
                  displayName={`${contact.firstName} ${contact.lastName}`}
                  style={{ marginBottom: '12px' }}
                />
                <span style={{ maxWidth: 200, fontSize: 12 }}>
                  {photoURL?.split('/').pop() ||
                    'Click to upload image, or drop an image here. Max file size 5MB.'}
                </span>
              </ProfileDropZone>
            </section>
          )}
        </Dropzone>
      )}

      <Formik
        initialValues={contact}
        validationSchema={ContactSchema}
        onSubmit={async (formValues, { setSubmitting, resetForm }) => {
          setFormError('')

          const propertyInfo = formValues.propertyInfo.find(
            p => p.associationId === primaryAssociation.id,
          )

          if (propertyInfo && !propertyInfo?.actId) {
            propertyInfo.actId =
              actChoices.find(act => act.label === AccessLevel.NoAccess)
                ?.value || ''
          }

          // remove empty units
          const removeEmptyUnits = () => {
            if (propertyInfo?.units) {
              return propertyInfo?.units.filter(
                u => u.unitId && u.unitId.length > 0,
              )
            }
            return []
          }

          const units = removeEmptyUnits()

          if (isResident && units.length === 0) {
            setFormError('Residents must have a unit.')
            setSubmitting(false)
            return
          }

          // validate primary phone
          if (formValues.phone !== undefined) {
            const { status, message } = validatePhone(
              formValues.phone,
              true,
              formValues,
            )
            if (!status) {
              setFormError(message)
              setSubmitting(false)
              return
            }
          }

          const validateOrRemoveSecondaryPhones = () => {
            if (formValues.secondaryPhones !== undefined) {
              return formValues.secondaryPhones.map(p => {
                if (p.number.length === 0) {
                  if (formValues.secondaryPhones.length === 1) {
                    _.omit(formValues, 'secondaryPhones')
                  } else {
                    formValues.secondaryPhones.filter(
                      x => x.number !== p.number,
                    )
                  }
                  return true
                }
                const { status, message } = validatePhone(p, false, formValues)
                if (!status) {
                  setFormError(message)
                  return false
                }
                return true
              })
            }

            return [true]
          }

          // validate primary email
          if (formValues.email !== undefined) {
            const { status, message } = validateEmail(
              formValues.email,
              true,
              formValues,
            )
            if (!status) {
              setFormError(message)
              return
            }
          }

          // validate or remove secondary emails
          const validateOrRemoveSecondaryEmails = () => {
            if (formValues.secondaryEmails !== undefined) {
              return formValues.secondaryEmails.map(email => {
                if (email.length === 0) {
                  if (formValues.secondaryEmails.length === 1) {
                    _.omit(formValues, 'secondaryPhones')
                  } else {
                    formValues.secondaryEmails.filter(x => x !== email)
                  }
                  return true
                }
                const { status, message } = validateEmail(
                  email,
                  false,
                  formValues,
                )
                if (!status) {
                  setFormError(message)
                  return false
                }
                return true
              })
            }

            return [true]
          }

          // ensure the contact's primary email is not removed
          if (
            contact.email &&
            contact?.email.length > 0 &&
            formValues.email &&
            formValues.email.length === 0
          ) {
            setFormError('A primary email is required')
            setSubmitting(false)
            return
          }

          const arePhonesValid = validateOrRemoveSecondaryPhones()
          const areEmailsValid = validateOrRemoveSecondaryEmails()

          if (
            arePhonesValid.includes(false) ||
            areEmailsValid.includes(false)
          ) {
            setSubmitting(false)
            return
          }

          if (contact.id) {
            // Update an existing contact
            try {
              const result = await updateContact({
                ...formValues,
                companyId: authenticatedUser.selectedCompany.id,
                photoURL,
              })

              setSubmitting(false)
              toastSuccess('Changes saved.')
              resetForm({ values: formValues })

              setProfileModal({
                ...profileModal,
                selectedContact: result,
                editProfileIsOpen: false,
                corpFirst: false,
              })
              if (isSettingsPage) {
                setAuthenticatedUser({
                  ...authenticatedUser,
                  selectedContact: result,
                })
              }
            } catch (err) {
              // @ts-expect-error - HttpsErrors contain a message property
              if (err?.message) {
                // @ts-expect-error - HttpsErrors contain a message property
                toastError(`${err?.message}`)
              } else {
                toastError('There was an error updating the contact.')
              }
            }
          }
          // Create a new contact
          else {
            // Use the selected contact's company ID
            const { companyId } = authenticatedUser.selectedContact

            try {
              const { data } = await createContact({
                companyId,
                contact: _.omit(formValues, 'id'),
              })

              setSubmitting(false)
              toastSuccess('Contact added successfully.')

              setProfileModal({
                ...profileModal,
                selectedContact: data,
                editProfileIsOpen: false,
              })
            } catch (err: unknown) {
              // @ts-expect-error - HttpsErrors contain a message property
              if (err?.message) {
                // @ts-expect-error - HttpsErrors contain a message property
                toastError(`${err?.message}`)
              } else {
                toastError('There was an error adding the contact.')
              }
            }
          }
        }}
      >
        {({ values, setFieldValue, errors, isSubmitting }) => {
          const propertyInfo = values.propertyInfo.find(
            p => p.associationId === primaryAssociation.id,
          )

          return (
            <Form
              style={{
                width: windowDimensions.isMobile
                  ? windowDimensions.width * 0.95
                  : 600,
              }}
            >
              <InputGroup>
                <InputGroupLabel>Name</InputGroupLabel>
                <FlexRow
                  justify="space-between"
                  style={{
                    height: contact.aiAssistant
                      ? (windowDimensions.height - 380) * 0.9
                      : 'unset',
                  }}
                >
                  <FieldWithError marginRight>
                    <Field
                      name="firstName"
                      type="text"
                      placeholder="First name"
                      as={TextInput}
                      style={{ width: '100%' }}
                    />
                    <ErrorMessage name="firstName" component={ErrorText} />
                  </FieldWithError>
                  <FieldWithError>
                    <Field
                      name="lastName"
                      type="text"
                      placeholder="Last name"
                      as={TextInput}
                      style={{ width: '100%' }}
                    />
                    <ErrorMessage name="lastName" component={ErrorText} />
                  </FieldWithError>
                </FlexRow>
              </InputGroup>
              {!contact.aiAssistant && (
                <>
                  {isAllowed(
                    authenticatedUser.selectedContact,
                    authenticatedUser.acts,
                    authenticatedUser.selectedContact.associationIds,
                    ResourceType.PROPERTY,
                    Feature.TASKS,
                    Action.EDIT,
                    CheckType.ANY,
                  ) &&
                    !contact.id && (
                      <InputGroup>
                        <InputGroupLabel>Access level</InputGroupLabel>
                        <Field name="propertyInfo[0].actId" as={Select}>
                          {actChoices.map(act => (
                            <option key={act.value} value={act.value}>
                              {act.label}
                            </option>
                          ))}
                        </Field>
                      </InputGroup>
                    )}
                  {[ContactGroup.Vendors, ContactGroup.Staff].some(x =>
                    propertyInfo?.groups?.includes(x),
                  ) && (
                    <InputGroup>
                      <InputGroupLabel>Company</InputGroupLabel>
                      <FlexRow align="center">
                        <Field
                          name="businessName"
                          type="text"
                          placeholder="Company name"
                          as={TextInput}
                        />
                      </FlexRow>
                      <InputGroupLabel>Vendor EIN</InputGroupLabel>
                      <FlexRow align="center">
                        <Field
                          name="ein"
                          type="text"
                          placeholder="EIN"
                          as={TextInput}
                        />
                      </FlexRow>
                      <InputGroupLabel>Website</InputGroupLabel>
                      <FlexRow align="center">
                        <Field
                          name="website"
                          type="text"
                          placeholder="www.example.com"
                          as={TextInput}
                        />
                      </FlexRow>
                    </InputGroup>
                  )}
                  {isResident &&
                    propertyInfo?.associationId &&
                    isAllowed(
                      authenticatedUser.selectedContact,
                      authenticatedUser.acts,
                      [propertyInfo?.associationId || ''],
                      ResourceType.PROPERTY,
                      Feature.TASKS,
                      Action.EDIT,
                      CheckType.ANY,
                    ) && (
                      <UnitsSection
                        isProfileForm
                        propertyInfo={propertyInfo}
                        associationId={propertyInfo.associationId}
                        setFieldValue={
                          (unitIndex: any, value: ContactUnit) =>
                            setFieldValue(`propertyInfo[0].${unitIndex}`, value) // units only show if it's a new contact
                        }
                        units={propertyInfo?.units ?? []}
                      />
                    )}

                  <EmailSection
                    setFieldValue={setFieldValue}
                    values={values}
                    validateEmail={validateEmail}
                  />
                  <PhoneSection
                    setFieldValue={setFieldValue}
                    values={values}
                    validatePhone={validatePhone}
                  />
                  <InputGroup>
                    <InputGroupLabel>Mailing address</InputGroupLabel>
                    <FlexRow align="center">
                      <Field
                        name="mailingAddress.line1"
                        type="text"
                        placeholder="Address line 1"
                        as={TextInput}
                      />
                    </FlexRow>
                    <FlexRow align="center">
                      <Field
                        name="mailingAddress.line2"
                        type="text"
                        placeholder="Address line 2"
                        as={TextInput}
                      />
                    </FlexRow>
                    <FlexRow align="center">
                      <Field
                        name="mailingAddress.city"
                        type="text"
                        placeholder="City"
                        as={TextInput}
                        style={{ marginRight: 8 }}
                      />
                      <Field
                        name="mailingAddress.state"
                        as={Select}
                        style={{ width: 100, marginRight: 8 }}
                      >
                        {usStateAbbreviations.map(x => (
                          <option key={x.abbreviation} value={x.abbreviation}>
                            {x.abbreviation}
                          </option>
                        ))}
                      </Field>
                      <Field
                        name="mailingAddress.zip"
                        type="text"
                        placeholder="Zip"
                        as={TextInput}
                        style={{ width: 100 }}
                      />
                    </FlexRow>
                  </InputGroup>
                  {isResident && (
                    <InputGroup>
                      <InputGroupLabel>Emergency contact</InputGroupLabel>
                      <FlexRow align="center">
                        <Field
                          name="emergencyContact.firstName"
                          type="text"
                          placeholder="First name"
                          as={TextInput}
                          style={{ marginRight: 8 }}
                        />
                        <Field
                          name="emergencyContact.lastName"
                          type="text"
                          placeholder="Last name"
                          as={TextInput}
                        />
                      </FlexRow>
                      <FlexRow align="center">
                        <Field
                          name="emergencyContact.relationship"
                          type="text"
                          placeholder="Relationship"
                          as={TextInput}
                        />
                      </FlexRow>
                      <FlexRow align="center">
                        <Field
                          name="emergencyContact.email"
                          type="text"
                          placeholder="Email address"
                          as={TextInput}
                          style={{ marginRight: 8 }}
                        />
                        <Field
                          name="emergencyContact.phone"
                          type="text"
                          placeholder="Phone number"
                          as={TextInput}
                        />
                      </FlexRow>
                    </InputGroup>
                  )}
                  {isVendorOrStaff && contact?.id && (
                    <InputGroup>
                      <InputGroupLabel>Files</InputGroupLabel>
                      <div>
                        <ExplanatoryText>
                          Upload any relevant files like COIs, contracts, or
                          other documents
                        </ExplanatoryText>
                      </div>
                      <FileManager
                        path={fileManagerPath}
                        contactRef={modifiedBy}
                      />
                    </InputGroup>
                  )}
                </>
              )}

              {isSettingsPage ? (
                <>
                  <div
                    style={{
                      height: 100,
                    }}
                  />
                  <div
                    style={{
                      position: 'fixed',
                      bottom: 0,
                      paddingBottom: 24,
                      width: '90%',
                      backgroundColor: '#fff',
                    }}
                  >
                    <Divider />
                    <FlexRow align="center" justify="flex-start">
                      {formError.length > 0 && (
                        <ErrorText>{formError}</ErrorText>
                      )}
                      <PrimaryButton type="submit" disabled={isSubmitting}>
                        {contact?.id ? `Update` : `Save`}
                      </PrimaryButton>
                    </FlexRow>
                  </div>
                </>
              ) : (
                <FlexRow align="center" justify="flex-end">
                  {formError.length > 0 && <ErrorText>{formError}</ErrorText>}
                  <TextButton
                    type="button"
                    onClick={() => onRequestClose()}
                    style={{ marginRight: 4 }}
                  >
                    Cancel
                  </TextButton>
                  <PrimaryButton type="submit" disabled={isSubmitting}>
                    {contact?.id ? `Update` : `Save`}
                  </PrimaryButton>
                </FlexRow>
              )}
            </Form>
          )
        }}
      </Formik>
    </>
  )
}

export default ProfileForm
