import { collection, getDocs, getFirestore } from 'firebase/firestore'
import { APIACT } from '@super-software-inc/foundation'
import { firebaseApp } from '../firebase/setup'

const getAccessControlTypes = async (
  companyIds: string[],
): Promise<APIACT[]> => {
  const db = getFirestore(firebaseApp)
  const accessControlTypesRef = collection(db, 'accessControlTypes')

  try {
    // Replace for...of loop with Promise.all and map
    const companyACTsPromises = companyIds.map(async companyId => {
      const companyACTsRef = collection(
        db,
        'companies',
        companyId,
        'accessControlTypes',
      )

      try {
        const companySnapshot = await getDocs(companyACTsRef)
        return companySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data(),
        })) as APIACT[]
      } catch (error) {
        // Return empty array instead of using continue
        return [] as APIACT[]
      }
    })

    // Get all company ACTs in parallel
    const companyACTsArrays = await Promise.all(companyACTsPromises)
    const companyACTs = companyACTsArrays.flat()

    const snapshot = await getDocs(accessControlTypesRef)
    const globalActs = snapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
    })) as APIACT[]

    return [...globalActs, ...companyACTs]
  } catch (error) {
    return []
  }
}

export default getAccessControlTypes
