import { Timezone } from '@super-software-inc/foundation/dist/types/Company'
import { MultilevelItem } from 'components/lib'
import FlexibleMultilevelDropdown from 'components/lib/MultilevelDropdown/FlexibleMultilevelDropdown'
import React from 'react'

const allTimezones = [
  Timezone.Eastern,
  Timezone.Central,
  Timezone.Mountain,
  Timezone.Pacific,
]

const TimezoneSelector = ({
  selectedValue,
  setSelectedValue,
}: {
  selectedValue: Timezone | undefined
  setSelectedValue: Function
}) => (
  <FlexibleMultilevelDropdown
    title={
      <p style={{ fontSize: 14, color: '#0A1F44', fontWeight: 400 }}>
        {selectedValue || 'Click to select'}
      </p>
    }
  >
    {allTimezones.map(timezone => (
      <MultilevelItem key={timezone} onClick={() => setSelectedValue(timezone)}>
        <p>{timezone}</p>
      </MultilevelItem>
    ))}
  </FlexibleMultilevelDropdown>
)

export default TimezoneSelector
