import React from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  Action,
  ContactGroup,
  Feature,
  isAllowed,
  ResourceType,
} from '@super-software-inc/foundation'
import { PropertyInfo } from '@super-software-inc/foundation/dist/types/Contact'
import { IconButton } from 'components/lib'
import MultilevelDropdown, {
  MultilevelItem,
  Positions,
} from 'components/lib/MultilevelDropdown'
import { toastSuccess } from 'components/lib/Toast'
import { MdMoreHoriz } from 'react-icons/md'
import { authenticatedUserAtom, profileModalAtom } from 'state/atoms'
import {
  primaryAssociationSelector,
  selectedAssociationChoicesAtom,
} from '../../../AppRoutes'
import UncategorizedActionDropdown from './UncategorizedActionDropdown'

interface ContactActionDropdownProps {
  contact: any
}

const ContactActionDropdown: React.FC<ContactActionDropdownProps> = ({
  contact,
}) => {
  // this component is only available when 1 association is selected
  const primaryAssociation = useRecoilValue(primaryAssociationSelector)

  const selectedAssociationChoices = useRecoilValue(
    selectedAssociationChoicesAtom,
  )
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)

  const contactPropertyInfo = contact.propertyInfo.find(
    (c: PropertyInfo) => c.associationId === primaryAssociation.id,
  )

  const canEditThisContact =
    authenticatedUser.selectedContact?.id === contact.id ||
    isAllowed(
      authenticatedUser.selectedContact,
      authenticatedUser.acts,
      [primaryAssociation.id],
      ResourceType.PROPERTY,
      Feature.CONTACTS,
      Action.EDIT,
    )

  const canOpenEditModal =
    canEditThisContact &&
    ![ContactGroup.Uncategorized].some(group =>
      contactPropertyInfo?.groups?.includes(group),
    )

  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)

  return (
    <MultilevelDropdown
      inheritStyles
      position={Positions.Left}
      title={
        <IconButton style={{ marginLeft: 'auto' }}>
          <MdMoreHoriz />
        </IconButton>
      }
    >
      <MultilevelItem
        onClick={() => {
          setProfileModal({
            ...profileModal,
            selectedContact: contact,
            sidebarIsOpen: true,
          })
        }}
      >
        View profile
      </MultilevelItem>
      {isAllowed(
        authenticatedUser.selectedContact,
        authenticatedUser.acts,
        [primaryAssociation.id],
        ResourceType.PROPERTY,
        Feature.CONTACTS,
        Action.VIEW,
      ) && (
        <MultilevelItem
          onClick={() => {
            window.navigator.clipboard.writeText(
              `${window.location.origin}/association/directory/${contact.id}`,
            )
            toastSuccess(`Link copied.`)
          }}
        >
          Copy link
        </MultilevelItem>
      )}

      {/* can only merge uncategorized in single association view  */}
      {canEditThisContact &&
        selectedAssociationChoices.length === 1 &&
        contactPropertyInfo?.groups?.includes(ContactGroup.Uncategorized) && (
          <UncategorizedActionDropdown contact={contact} />
        )}

      {canOpenEditModal && (
        <MultilevelItem
          onClick={() => {
            setProfileModal({
              ...profileModal,
              selectedContact: contact,
              editProfileIsOpen: true,
            })
          }}
        >
          Edit profile
        </MultilevelItem>
      )}
    </MultilevelDropdown>
  )
}

export default ContactActionDropdown
