import * as Sentry from '@sentry/react'
import {
  Action,
  APIAssociation,
  APICompany,
  APIContact,
  APIHeartbeat,
  APISystemMessage,
  APIUnit,
  Category,
  CheckType,
  ContactGroup,
  ContactSecrets,
  Feature,
  isAllowed,
  ResourceType,
} from '@super-software-inc/foundation'
import ACLProtectedZone from 'components/app/ACLProtectedZone'
import SystemMessageBanner from 'components/app/SystemMessageBanner'
import { Logo } from 'components/lib'
import ToastProvider, { toastError } from 'components/lib/Toast'
import { getAuth, onAuthStateChanged, User } from 'firebase/auth'
import {
  collection,
  collectionGroup,
  documentId,
  Firestore,
  getDocs,
  getFirestore,
  query,
  where,
} from 'firebase/firestore'
import { httpsCallable } from 'firebase/functions'
import useSetContactsCache from 'hooks/useSetContactsCache'
import { isFeatureEnabled } from 'lib/featureFlags'
import { orderBy, uniqBy } from 'lodash'
import AdminDashboard from 'pages/AdminDashboard'
import AdminManagement from 'pages/AdminManagement'
import AnnouncementsPage, {
  selectedAnnouncementAtom,
} from 'pages/Announcements'
import AssociationDetailPage from 'pages/Association'
import DashboardLayout from 'pages/Dashboard'
import DeliveriesPage from 'pages/Deliveries'
import Directory from 'pages/Directory/Directory'
import FilesPage from 'pages/Files'
import FinancesPage from 'pages/Finances'
import NothingPage from 'pages/NothingPage'
import SandboxPage from 'pages/Sandbox'
import Loading from 'pages/Secondary/Loading'
import NewUserPage from 'pages/Secondary/NewUser'
import NoAccess from 'pages/Secondary/NoAccess'
import Page404 from 'pages/Secondary/Page404'
import SigninPage from 'pages/Secondary/Signin'
import UnsubscribePage from 'pages/Secondary/Unsubscribe'
import Settings from 'pages/Settings'
import TasksPage, {
  companyTaskCategoriesAtom,
  selectedTaskAtom,
} from 'pages/Tasks'
import VerifyLinkPage from 'pages/VerifyLink'
import React, { ReactElement, useEffect, useRef, useState } from 'react'
import {
  Navigate,
  Route,
  Routes,
  useLocation,
  useNavigate,
} from 'react-router-dom'
import { useMountedState } from 'react-use'
import { useFirestore, useFunctions, useSigninCheck } from 'reactfire'
import { atom, selector, useRecoilState } from 'recoil'
import {
  authenticatedUserAtom,
  companyAssociationsAtom,
  heartbeatAtom,
  mobileOperatingSystemAtom,
} from 'state/atoms'
import { findAnnouncementById } from 'api/annoucements/annoucements'
import getMobileOperatingSystem from 'utils/getMobileOperatingSystem'
import { getTaskFromTypesense } from 'api/tasks'
import getAccessControlTypes from 'api/accessControl'
import { firebaseApp } from './firebase/setup'

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes)

// TODO: these atoms/selectors should all be moved to the state/atoms module
export const associationChoicesAtom = atom<APIAssociation[]>({
  key: 'associationChoices',
  default: [],
})

export const selectedAssociationChoicesAtom = atom<APIAssociation[]>({
  key: 'selectedAssociationChoices',
  default: [],
})

// TODO: #corporations - proper typing would probably be <APIAssociation |
// undefined> but this is working for now
export const primaryAssociationSelector = selector<APIAssociation>({
  key: 'primaryAssociation',
  get: ({ get }) => {
    const associations = get(selectedAssociationChoicesAtom)

    return associations[0] ?? {}
  },
})

export const selectedAssociationChoicesIdsSelector = selector({
  key: 'selectedAssociationChoicesIds',
  get: ({ get }) => {
    const associations = get(selectedAssociationChoicesAtom)

    return associations.map(a => a.id)
  },
})

export const associationChoicesIdsSelector = selector({
  key: 'associationChoicesIds',
  get: ({ get }) => {
    const associations = get(associationChoicesAtom)

    return associations.map(a => a.id)
  },
})

export const userIsNewAtom = atom({
  key: 'userIsNew',
  default: false,
})

export const windowDimensionsAtom = atom({
  key: 'windowDimensions',
  default: {
    width: window.innerWidth,
    height: window.innerHeight,
    isTablet: window.innerWidth <= 768 && window.innerWidth > 450,
    isMobile: window.innerWidth <= 450,
    isIframe: window !== window.parent,
  },
})

// TODO: We may want to move this elsewhere later...
export const getBatchedCollectionDocs = async (
  firestore: Firestore,
  collectionName: string,
  docIds: string[],
  opts?: {
    queryType: 'collection' | 'collectionGroup'
    idField: string
  },
) => {
  // Group association IDs in lists of 10 or less to batch Firestore queries
  const prepareIdsForQuery = (ids: string[]) =>
    ids.reduce((acc, cur, idx) => {
      if (idx % 10 === 0) {
        acc.push([])
      }

      acc[acc.length - 1].push(cur)

      return acc
    }, [] as string[][])

  return (
    await Promise.all(
      prepareIdsForQuery(docIds).map(async (ids: string[]) =>
        getDocs(
          query(
            opts?.queryType === 'collectionGroup'
              ? collectionGroup(firestore, collectionName)
              : collection(firestore, collectionName),
            where(opts?.idField || documentId(), 'in', ids),
          ),
        ).then(data => data.docs.map(d => ({ ...d.data(), id: d.id }))),
      ),
    )
  ).flat()
}

const ProtectedPage = ({ children }: { children: ReactElement }) => {
  const { status, data: signInCheckResult } = useSigninCheck()

  if (!status || status === 'loading') {
    return <Loading />
  }

  if (signInCheckResult.signedIn === true) {
    return children
  }

  return <SigninPage />
}

export interface AuthCompanyContacts {
  companies: APICompany[]
  contacts: APIContact[]
  secrets: ContactSecrets[]
}

const AuthorizedRoutes = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [heartbeat, setHeartbeat] = useRecoilState<APIHeartbeat>(heartbeatAtom)
  const [systemMessageHidden, setSystemMessageHidden] = useState(false)
  const [, setCompanyTaskCategories] = useRecoilState(companyTaskCategoriesAtom)

  const [associationChoices, setAssociationChoices] = useRecoilState(
    associationChoicesAtom,
  )
  const [selectedAssociations, setSelectedAssociations] = useRecoilState(
    selectedAssociationChoicesAtom,
  )
  const [authenticatedUser, setAuthenticatedUser] = useRecoilState(
    authenticatedUserAtom,
  )

  const [companyAssociations, setCompanyAssociations] = useRecoilState(
    companyAssociationsAtom,
  )

  // This atom tracks the window size throughout the app
  // you can quickly reference if styling should break for tablet or mobile
  const [, setWindowDimensions] = useRecoilState(windowDimensionsAtom)

  const getAssociations = async (ids: string[]) => {
    const db = getFirestore(firebaseApp)

    const associationsData = (await getBatchedCollectionDocs(
      db,
      'associations',
      ids,
    )) as APIAssociation[]

    return associationsData
  }

  // Populate the contacts cache for all the associations the user has access to
  useSetContactsCache()

  useEffect(() => {
    // hacky way to set the height and width of the iframe
    // on first load, it's always 0 so we need to request it again after initial load
    setWindowDimensions({
      width: window.innerWidth,
      height: window.innerHeight,
      isTablet: window.innerWidth <= 768 && window.innerWidth > 450,
      isMobile: window.innerWidth <= 450,
      isIframe: window.top !== window.self,
    })

    if (
      authenticatedUser.selectedCompany.id !== companyAssociations.companyId
    ) {
      const getCompanyAssociations = async () => {
        const associationsData = await getAssociations(
          authenticatedUser.selectedCompany.associationIds,
        )

        setCompanyAssociations({
          associations: associationsData.sort((a, b) =>
            a.name.localeCompare(b.name),
          ),
          companyId: authenticatedUser.selectedCompany.id,
        })
      }
      getCompanyAssociations()
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedUser.selectedCompany?.id, companyAssociations.companyId])

  const appBuildDate = process.env.REACT_APP_BUILD_DATE
    ? new Date(Number(process.env.REACT_APP_BUILD_DATE) * 1000)
    : new Date()

  // Show the banner if there is a system message that is newer than the app build date
  // and the user hasn't (yet) hidden it
  const showBanner =
    heartbeat?.latestSystemMessage?.createdAt &&
    new Date(heartbeat.latestSystemMessage.createdAt) > appBuildDate &&
    !systemMessageHidden
  const firestore = useFirestore()

  const functions = useFunctions()
  const heartbeatApi = httpsCallable(functions, 'heartbeat')

  const isMounted = useMountedState()

  function callHeartbeat() {
    functions.region = 'us-east1'

    heartbeatApi().then(result => {
      if (isMounted()) {
        const data = result.data as {
          latestSystemMessage: APISystemMessage | undefined
        }
        setHeartbeat(data as APIHeartbeat)
      }
    })
  }

  useEffect(() => {
    function handleResize() {
      setWindowDimensions({
        width: window.innerWidth,
        height: window.innerHeight,
        isTablet: window.innerWidth <= 768 && window.innerWidth > 450,
        isMobile: window.innerWidth <= 450,
        isIframe: window.top !== window.self,
      })
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [setWindowDimensions])

  const persistSelectedAssociations = (associations: APIAssociation[]) => {
    const ids = associations.map(association => association.id)
    window.localStorage.setItem('selectedAssociations', JSON.stringify(ids))
  }

  const persistSelectedCompany = (company: APICompany) =>
    window.localStorage.setItem('selectedCompany', company.id)

  const initialRender = useRef(true)

  useEffect(() => {
    const getContactAssociations = async () => {
      if (
        authenticatedUser.companies.length === 0 ||
        authenticatedUser.contacts.length === 0 ||
        !authenticatedUser.selectedContact
      ) {
        return
      }

      // if the url has a slug, use it to set the selected association
      const associationsData = await getAssociations(
        authenticatedUser.selectedContact.associationIds,
      )

      // Use existing (persisted) choices if possibles
      const persistedIds = JSON.parse(
        window.localStorage.getItem('selectedAssociations') || '[]',
      )
      const persistedData = associationsData.filter(a =>
        persistedIds.includes(a.id),
      )

      if (persistedData.length) {
        // if there's persisted HOA selection data, use it:
        setSelectedAssociations(persistedData)
      } else {
        // Otherwise, if there's a single HOA, use it:
        setSelectedAssociations(
          associationsData[0] ? [associationsData[0]] : [],
        )
      }

      // Finally, set the association choices upon setting the selected associations
      setAssociationChoices(associationsData)
    }

    getContactAssociations()

    // TODO - MOVE THIS TO AN API
    const getCategories = async () => {
      const companyCategories: Category[] = []

      const categoriesQuery = collection(
        firestore,
        'companies',
        authenticatedUser.selectedCompany.id,
        'companyTaskCategories',
      )
      const categoriesResult = await getDocs(categoriesQuery)
      await Promise.all(
        categoriesResult.docs.map(async category => {
          companyCategories.push({
            id: category.id,
            ...category.data(),
          } as Category)
        }),
      )

      return companyCategories
    }
    const categoryResults = getCategories()
    const sortCategories = function _(tag: Category) {
      return [tag.name.toLowerCase()]
    }
    categoryResults.then(result => {
      setCompanyTaskCategories(orderBy(uniqBy(result, 'name'), sortCategories))
    })

    // Only call the heartbeat when the user is authenticated
    if (authenticatedUser.selectedContact) {
      callHeartbeat()

      // Periodically call the heartbeat function to check for system messages
      const timer = window.setInterval(() => {
        callHeartbeat()
      }, 30000)

      return () => {
        window.clearInterval(timer)
      }
    }

    return () => {
      // No-op
    }

    // Exhaustive-deps is wrong here.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authenticatedUser])

  // Redirect users based on association data changes
  useEffect(() => {
    // Support legacy /rollup route for now:
    if (location.pathname.startsWith('/rollup')) {
      navigate(location.pathname.replace('/rollup', ''))
      return
    }

    // add a check for slugs in the route, remove the slug, and set that as the selected association

    const checkSlugInRoute = async () => {
      const { companies } = authenticatedUser
      const allAssociationIds = companies.map(c => c.associationIds).flat()

      const associationsData = await getAssociations(allAssociationIds)
      const routeBranches = location.pathname.split('/')
      const requestedAssociation = associationsData?.find(a => {
        const slug = routeBranches[1]

        return a.slug === slug
      })

      // remove slug from the route if it's a valid association
      // dont remove the slug for unsubscribe - will remove unsubscribe completely from emails soon
      if (requestedAssociation && !routeBranches.includes('unsubscribe')) {
        routeBranches.shift()
        routeBranches.shift()
        const newRoute = routeBranches.join('/')
        setAuthenticatedUser({
          ...authenticatedUser,
          selectedCompany: companies.find(c =>
            c.associationIds.includes(requestedAssociation.id),
          ) as APICompany,
          selectedContact: authenticatedUser.contacts.find(c =>
            c.associationIds.includes(requestedAssociation.id),
          ) as APIContact,
        })
        setSelectedAssociations([requestedAssociation])

        navigate(newRoute, { replace: true })
      }
    }

    checkSlugInRoute()

    // First render will be the initial state, so don't persist it.

    if (initialRender.current) {
      initialRender.current = false
    } else {
      persistSelectedAssociations(selectedAssociations)
      persistSelectedCompany(authenticatedUser.selectedCompany)
    }

    // Skip if there is no data, if we're on a task page or if the user is
    // going through the onboarding flow
    if (!associationChoices?.length || location.pathname.includes('new-user')) {
      return
    }

    const { pathname } = location
    const searchParams = window.location.search

    navigate(`${pathname}${searchParams}`)

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAssociations, associationChoices])

  return (
    <>
      <SystemMessageBanner
        systemMessage={showBanner ? heartbeat.latestSystemMessage : undefined}
        onHide={() => setSystemMessageHidden(true)}
      />
      <SentryRoutes>
        {/* Auth-related public routes */}
        <Route path="verifyLink" element={<VerifyLinkPage />} />
        <Route path="auth" element={<SigninPage />} />
        <Route path="new-user" element={<NewUserPage />} />

        {/* All HOA view */}

        <Route
          path="/admin"
          element={
            <ProtectedPage>
              <DashboardLayout />
            </ProtectedPage>
          }
        >
          <Route index path="*" element={<AdminManagement />} />
        </Route>

        <Route
          path="/directory"
          element={
            <ProtectedPage>
              <DashboardLayout />
            </ProtectedPage>
          }
        >
          <Route index path="*" element={<Directory />} />
        </Route>

        <Route
          path="/settings"
          element={
            <ProtectedPage>
              <DashboardLayout />
            </ProtectedPage>
          }
        >
          <Route index path="*" element={<Settings />} />
        </Route>

        <Route
          path="/tasks/*"
          element={
            <ProtectedPage>
              <DashboardLayout />
            </ProtectedPage>
          }
        >
          <Route index element={<TasksPage />} />
          <Route path="*" element={<TasksPage />} />
        </Route>

        <Route
          path="/dashboard"
          element={
            <ProtectedPage>
              <DashboardLayout />
            </ProtectedPage>
          }
        >
          <Route index path="*" element={<AdminDashboard />} />
        </Route>

        <Route
          path="/announcements"
          element={
            <ProtectedPage>
              <DashboardLayout />
            </ProtectedPage>
          }
        >
          <Route index element={<AnnouncementsPage />} />
          <Route path="*" element={<AnnouncementsPage />} />
        </Route>

        <Route
          path="/deliveries/*"
          element={
            <ProtectedPage>
              <DashboardLayout />
            </ProtectedPage>
          }
        >
          <Route index element={<DeliveriesPage />} />
          <Route path="*" element={<DeliveriesPage />} />
        </Route>

        {/* Unsubscribe page */}
        <Route
          path="/:slug/tasks/:taskId/unsubscribe"
          element={<UnsubscribePage />}
        />

        {/* Single HOA view */}
        <Route
          path="/"
          element={
            <ProtectedPage>
              <DashboardLayout />
            </ProtectedPage>
          }
        >
          <Route path="/" element={<Navigate to="/tasks" />} />
          <Route path="association/*" element={<AssociationDetailPage />} />
          <Route path="sandbox" element={<SandboxPage />} />
          <Route path="packages" element={<Navigate to="/deliveries" />} />
          <Route
            path="finances/*"
            element={
              <ACLProtectedZone
                field={isAllowed(
                  authenticatedUser.selectedContact,
                  authenticatedUser.acts,
                  authenticatedUser.selectedContact.associationIds,
                  ResourceType.PROPERTY,
                  Feature.ASSOCIATION_FINANCES,
                  Action.VIEW,
                  CheckType.ANY,
                )}
                fallback={<NoAccess />}
              >
                <FinancesPage />
              </ACLProtectedZone>
            }
          />
          <Route
            path="files/*"
            element={
              <ACLProtectedZone
                field={isAllowed(
                  authenticatedUser.selectedContact,
                  authenticatedUser.acts,
                  selectedAssociations.length > 0
                    ? selectedAssociations.map(a => a.id)
                    : [],
                  ResourceType.PROPERTY,
                  Feature.ASSOCIATION_FILES,
                  Action.VIEW,
                )}
                fallback={<NoAccess />}
              >
                <FilesPage />
              </ACLProtectedZone>
            }
          />
        </Route>

        <Route path="*" element={<Page404 />} />
      </SentryRoutes>
      <ToastProvider />
    </>
  )
}

const AppRoutes = () => {
  const functions = useFunctions()
  functions.region = 'us-east1'
  const location = useLocation()
  const auth = getAuth()

  const [ready, setReady] = useState(false)
  const [user, setUser] = useState<User | null>(null)
  const [isLoading, setIsLoading] = useState(false)

  const [userIsNew] = useRecoilState(userIsNewAtom)
  const [authenticatedUser, setAuthenticatedUser] = useRecoilState(
    authenticatedUserAtom,
  )

  const [, setSelectedAnnouncement] = useRecoilState(selectedAnnouncementAtom)
  const [, setMobileOperatingSystem] = useRecoilState(mobileOperatingSystemAtom)

  const findContact = httpsCallable(functions, 'findContact')
  const findUnit = httpsCallable(functions, 'findUnit')

  const [, setSelectedTask] = useRecoilState(selectedTaskAtom)

  useEffect(() => {
    onAuthStateChanged(auth, authUser => {
      setReady(true)

      if (authUser) {
        setUser(authUser)
      } else {
        setUser(null)
      }
    })
  }, [auth])

  useEffect(() => {
    setMobileOperatingSystem(getMobileOperatingSystem())
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getAuthenticatedContacts: () => Promise<{ data: AuthCompanyContacts }> =
    httpsCallable(functions, 'getAuthenticatedContacts')

  useEffect(() => {
    const getAuthenticatedUser = async () => {
      if (user?.uid) {
        try {
          if (window.location.pathname.includes('new-user')) {
            // There seems to be a race condition where the trigger function hasn't yet set the user's userId
            // So let's wait for about four seconds:
            await new Promise(resolve => {
              setTimeout(resolve, 4000)
            })
          }

          setIsLoading(true)
          const result = await getAuthenticatedContacts()
          const { companies, contacts, secrets } = result.data

          if (!contacts.length) {
            // TODO: #corporations if no contacts, the user does not have
            // access and we should kick them out at this point but they
            // probably should never get to this point...
          }
          const urlPathnames = location.pathname.split('/')
          const slugOrRoute = urlPathnames[1]

          const itemId = ['calendar', 'units', 'directory'].includes(
            urlPathnames[2],
          )
            ? urlPathnames[3]
            : urlPathnames[2]
          const companyIds = companies.map(c => c.id)
          const associationIds = companies.map(c => c.associationIds).flat()

          // GET all AccessControlTypes from firestore
          const accessControlTypes = await getAccessControlTypes(
            companies.map(c => c.id),
          )

          setAuthenticatedUser({
            ...authenticatedUser,
            acts: accessControlTypes,
          })

          if (slugOrRoute === 'tasks' && itemId && secrets.length > 0) {
            const findTaskFromSecret = async (secret: ContactSecrets) => {
              const task = await getTaskFromTypesense(itemId, secret)

              if (task) {
                return task
              }

              return null
            }

            const task = await Promise.all(
              secrets.map(findTaskFromSecret),
            ).then(x => x.find(t => t))

            if (!task) {
              setIsLoading(false)
              return
            }

            setSelectedTask(task)

            const selectedCompany = companies.find(
              c => c.id === task.companyId,
            ) as APICompany

            const selectedContact = contacts.find(
              c => c.companyId === selectedCompany.id,
            ) as APIContact

            const includeInternalComments = selectedContact.propertyInfo?.some(
              p =>
                (associationIds.includes(p.associationId) &&
                  p?.groups.some(
                    g =>
                      g === ContactGroup.Staff || g === ContactGroup.Management,
                  )) ||
                false,
            )

            // NOTE: In order to know whethever the contact can view internal
            // comments, we need `selectedContact` but we can't know that until
            // we have the `selectedCompany`, which requires the task data to
            // compute. Because of this, we need to make a second call to get
            // the task with internal comments if necessary.
            if (includeInternalComments) {
              const taskWithInternalComments = await getTaskFromTypesense(
                itemId,
                secrets.find(s => s.companyId === selectedCompany.id),
                includeInternalComments,
              )

              if (taskWithInternalComments) {
                setSelectedTask(taskWithInternalComments)
              }
            }

            setAuthenticatedUser({
              ...authenticatedUser,
              companies,
              contacts,
              selectedCompany,
              selectedContact,
              secrets,
              acts: accessControlTypes,
            })

            window.localStorage.setItem(
              'selectedAssociations',
              JSON.stringify([task.associationId]),
            )
          } else if (slugOrRoute === 'announcements' && itemId) {
            const announcement = await findAnnouncementById(
              companies.map(c => c.id),
              itemId,
            )

            if (!announcement) {
              setIsLoading(false)
              return
            }

            const selectedCompany = companies.find(
              c => announcement.companyId === c.id,
            ) as APICompany

            setAuthenticatedUser({
              ...authenticatedUser,
              companies,
              contacts,
              selectedCompany,
              selectedContact: contacts.find(
                c => c.companyId === selectedCompany.id,
              ) as APIContact,
              secrets,
              acts: accessControlTypes,
            })

            setSelectedAnnouncement(announcement)

            window.localStorage.setItem(
              'selectedAssociations',
              JSON.stringify(announcement.associationIds),
            )
          } else if (slugOrRoute === 'association' && itemId) {
            // TODO - This doesn't exactly work. Since we don't know the association Id, it could be any association the contact has.
            // walsh to fix this with global directory work
            if (urlPathnames[2] === 'directory') {
              const contactResult = await findContact({
                companyIds,
                contactId: itemId,
              })

              // TODO:  - this is a bit of a hack, but it works for now. find the first assoc that both the contact and authenticated user share
              const contact = contactResult.data as APIContact
              const selectedCompany = companies.find(c =>
                c.associationIds.some(aId =>
                  contact.associationIds.includes(aId),
                ),
              ) as APICompany

              const selectedContact = contacts.find(
                c => c.companyId === selectedCompany.id,
              ) as APIContact

              setAuthenticatedUser({
                ...authenticatedUser,
                companies,
                contacts,
                selectedCompany,
                selectedContact,
                secrets,
                acts: accessControlTypes,
              })

              const associationIdInCommon = selectedContact.associationIds.find(
                aId => contact.associationIds.includes(aId),
              )

              window.localStorage.setItem(
                'selectedAssociations',
                JSON.stringify([associationIdInCommon]),
              )
            } else if (urlPathnames[2] === 'units') {
              const unitResult = await findUnit({
                associationIds,
                unitId: itemId,
              })

              const unit = unitResult.data as APIUnit

              const selectedCompany = companies.find(c =>
                c.associationIds.includes(unit.associationId),
              ) as APICompany

              const selectedContact = contacts.find(
                c => c.companyId === selectedCompany.id,
              ) as APIContact

              setAuthenticatedUser({
                ...authenticatedUser,
                companies,
                contacts,
                selectedCompany,
                selectedContact,
                secrets,
                acts: accessControlTypes,
              })

              window.localStorage.setItem(
                'selectedAssociations',
                JSON.stringify([unit.associationId]),
              )
            }
          } else {
            const persistedCompany =
              window.localStorage.getItem('selectedCompany')

            // If a specific property is requested, use it; if an id is
            // persisted, use it; otherwise, use the first one

            const selectedCompany =
              (persistedCompany &&
                companies.find(c => c.id === persistedCompany)) ||
              companies[0]

            const selectedContact = contacts.find(
              (c: APIContact) => c.companyId === selectedCompany.id,
            )
            if (selectedContact) {
              setAuthenticatedUser({
                ...authenticatedUser,
                companies,
                contacts,
                selectedCompany,
                selectedContact,
                secrets,
                acts: accessControlTypes,
              })
            }
          }

          setIsLoading(false)
        } catch (err) {
          setIsLoading(false)
          toastError('There was a problem loading your account.')
        }
      }
    }

    getAuthenticatedUser()

    // We only pass `user` as a dependency here since this effect should only
    // run when the user is first authenticated and the app is loading
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  if (isFeatureEnabled('REACT_APP_DEPLOY_IN_PROGRESS')) {
    return (
      <SentryRoutes>
        <Route
          path="*"
          element={
            <div
              style={{
                margin: '4rem auto',
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <Logo />
              <div>
                Upgrade in progress. Please check back in a few minutes.
              </div>
            </div>
          }
        />
      </SentryRoutes>
    )
  }

  if (!ready) {
    return <Loading />
  }

  if (!user && !authenticatedUser.selectedContact?.id) {
    return (
      <SentryRoutes>
        <Route path="verifyLink" element={<VerifyLinkPage />} />
        <Route path="auth" element={<SigninPage />} />
        <Route path="*" element={<SigninPage />} />
      </SentryRoutes>
    )
  }

  if (isLoading) {
    return (
      <SentryRoutes>
        <Route path="*" element={<Loading />} />
      </SentryRoutes>
    )
  }

  if (user && !authenticatedUser.selectedContact?.id) {
    return (
      <SentryRoutes>
        <Route
          path="*"
          element={userIsNew ? <NewUserPage /> : <NothingPage />}
        />
      </SentryRoutes>
    )
  }

  return <AuthorizedRoutes />
}

export default AppRoutes
