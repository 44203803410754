import { APIContact } from '@super-software-inc/foundation'
import {
  FlexRow,
  LoadingIndicator,
  SortableTableHeaderCell,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeaderCell,
  TableRow,
  Tooltip,
  TruncatedText,
} from 'components/lib'
import { orderBy } from 'lodash'
import React, { ReactNode, useMemo, useState } from 'react'
import { MdErrorOutline } from 'react-icons/md'
import { HiMiniSparkles } from 'react-icons/hi2'
import { useRecoilState, useRecoilValue } from 'recoil'
import { getContactByContactId } from 'api/contacts'
import { authenticatedUserAtom, profileModalAtom } from 'state/atoms'
import styled, { useTheme } from 'styled-components/macro'
import formatPhoneNumber from 'utils/formatPhoneNumber'
import ContactAvatar from '../ContactAvatar'
import ContactActionDropdown from '../ProfileSidebar/ContactActionDropdown'
import MergeContacts from '../ProfileSidebar/MergeContacts'

const UncategorizedContactsContent = styled.div`
  padding: 12px 20px;
  margin: 20px 0;
  display: flex;
  flex-direction: column;
`
export const AutoCreatedLabel = () => {
  const theme = useTheme()
  return (
    <FlexRow align="center" style={{ color: theme.colors.secondaryPurple700 }}>
      <HiMiniSparkles style={{ marginRight: 4 }} /> Auto-created
    </FlexRow>
  )
}

export const AutoCreatedTooltip = ({ children }: { children: ReactNode }) => (
  <Tooltip
    overlay={
      <span>
        <strong>Auto-created contacts</strong> <br />
        When you communicate with someone <br />
        that is not already in this property’s <br />
        directory, Super saves them automatically <br />
        for you. We help you keep track of every <br />
        person you’ve engaged with!
      </span>
    }
    placement="bottom"
  >
    <FlexRow style={{ alignItems: 'center', cursor: 'default' }}>
      {children}
    </FlexRow>
  </Tooltip>
)

const UncategorizedContacts = ({
  contacts,
  isLoading,
  corpFirst = false,
}: {
  contacts: APIContact[]
  isLoading: boolean
  corpFirst?: boolean
}) => {
  const [profileModal, setProfileModal] = useRecoilState(profileModalAtom)
  const authenticatedUser = useRecoilValue(authenticatedUserAtom)
  type SortKeys = 'name' | 'title' | 'accessLevel' | 'email' | 'phone.number'

  const [sortKey, setSortKey] = useState<SortKeys>('name')
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('asc')

  const handleSort = (nextSort: SortKeys) => {
    if (nextSort === sortKey) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortKey(nextSort)
      setSortOrder('asc')
    }
  }

  const sortedContacts = useMemo(() => {
    if (sortKey === 'name') {
      return orderBy(contacts, ['firstName', 'lastName'], sortOrder)
    }

    return orderBy(contacts, sortKey, sortOrder)
  }, [contacts, sortKey, sortOrder])

  const handleTableRowClick = async (contact: APIContact) => {
    // get full contact from db and set it
    const fullContact = await getContactByContactId(
      authenticatedUser.selectedCompany.id,
      contact.id,
    )
    setProfileModal({
      ...profileModal,
      sidebarIsOpen: true,
      selectedContact: fullContact,
      corpFirst,
    })
  }

  return (
    <UncategorizedContactsContent>
      <Table>
        <TableHead>
          <TableRow>
            <SortableTableHeaderCell
              active={sortKey === 'name'}
              sortOrder={sortOrder}
              onClick={() => handleSort('name')}
            >
              <FlexRow align="center">
                <TruncatedText>Name</TruncatedText>
              </FlexRow>
            </SortableTableHeaderCell>
            <SortableTableHeaderCell
              active={false}
              sortOrder={sortOrder}
              onClick={() => {}}
              style={{ width: 150 }}
            >
              <FlexRow align="center">Type</FlexRow>
            </SortableTableHeaderCell>
            {!corpFirst && (
              <SortableTableHeaderCell
                active={sortKey === 'accessLevel'}
                sortOrder={sortOrder}
                onClick={() => handleSort('accessLevel')}
                style={{ width: 120 }}
              >
                <FlexRow align="center">Access Level</FlexRow>
              </SortableTableHeaderCell>
            )}
            <SortableTableHeaderCell
              active={sortKey === 'email'}
              sortOrder={sortOrder}
              onClick={() => handleSort('email')}
            >
              <FlexRow align="center">
                <TruncatedText>Email</TruncatedText>
              </FlexRow>
            </SortableTableHeaderCell>
            <SortableTableHeaderCell
              active={sortKey === 'phone.number'}
              sortOrder={sortOrder}
              onClick={() => handleSort('phone.number')}
              style={{ width: 160 }}
            >
              <FlexRow align="center">
                <TruncatedText>Phone</TruncatedText>
              </FlexRow>
            </SortableTableHeaderCell>
            {!corpFirst && <TableHeaderCell style={{ width: 50 }} />}
          </TableRow>
        </TableHead>
        <TableBody>
          {sortedContacts &&
            sortedContacts.length > 0 &&
            sortedContacts
              .filter(c => c !== undefined)
              .map(contact => (
                <TableRow key={contact.id}>
                  <TableCell onClick={() => handleTableRowClick(contact)}>
                    <FlexRow align="center">
                      <ContactAvatar
                        data={contact}
                        style={{ marginRight: 5 }}
                      />
                      <TruncatedText className="font-medium">
                        {contact.firstName || contact.lastName
                          ? `${contact.firstName} ${contact.lastName}`
                          : contact.email || contact.phone?.number || '--'}
                      </TruncatedText>
                    </FlexRow>
                  </TableCell>
                  <TableCell
                    onClick={() => handleTableRowClick(contact)}
                    style={{ width: 300 }}
                    className="text-slate-500"
                  >
                    <FlexRow align="center">
                      <AutoCreatedTooltip>
                        <AutoCreatedLabel />
                      </AutoCreatedTooltip>
                    </FlexRow>
                  </TableCell>
                  {!corpFirst && (
                    <TableCell
                      onClick={() => handleTableRowClick(contact)}
                      style={{ width: 120 }}
                      className="text-slate-500"
                    >
                      <FlexRow align="center">No access</FlexRow>
                    </TableCell>
                  )}
                  <TableCell
                    onClick={() => handleTableRowClick(contact)}
                    className="text-slate-500"
                  >
                    <FlexRow align="center">
                      {contact.email && contact.email.length > 1 ? (
                        <TruncatedText>{contact.email}</TruncatedText>
                      ) : (
                        <Tooltip overlay={<span>No email on file</span>}>
                          <MdErrorOutline
                            style={{ fontSize: 16, color: '#DC6803' }}
                          />
                        </Tooltip>
                      )}
                    </FlexRow>
                  </TableCell>
                  <TableCell
                    onClick={() => handleTableRowClick(contact)}
                    style={{ width: 160 }}
                    className="text-slate-500"
                  >
                    <FlexRow align="center">
                      <TruncatedText>
                        {contact.phone?.number &&
                          formatPhoneNumber(contact.phone?.number)}
                      </TruncatedText>
                    </FlexRow>
                  </TableCell>
                  {!corpFirst && (
                    <TableCell>
                      <ContactActionDropdown contact={contact} />
                    </TableCell>
                  )}
                </TableRow>
              ))}
          {isLoading && (
            <TableRow>
              <TableCell colSpan={6}>
                <LoadingIndicator />
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
      {contacts.length === 0 && (
        <FlexRow style={{ marginTop: 50 }} align="center" justify="center">
          <h3>No contacts to display</h3>
        </FlexRow>
      )}
      {profileModal.mergeContactIsOpen && (
        <MergeContacts
          redundantContact={profileModal.selectedContact}
          onRequestClose={() =>
            setProfileModal({
              ...profileModal,
              mergeContactIsOpen: false,
            })
          }
        />
      )}
    </UncategorizedContactsContent>
  )
}

export default UncategorizedContacts
