import React, { useEffect, useMemo, useState } from 'react'
import { useRecoilState, useRecoilValue } from 'recoil'
import {
  allHandoffRulesAtom,
  authenticatedUserAtom,
  companyAssociationsAtom,
  handoffRuleFormAtom,
} from 'state/atoms'
import {
  Table,
  TableBody,
  TableHead,
  TableHeaderCell,
  TableRow,
  TableWrapper,
  SortableTableHeaderCell,
} from 'components/lib/NewTable'
import { HandoffCommunicationType } from '@super-software-inc/foundation'
import { FlexRow, IconButton, TableCell, TextButton } from 'components/lib'
import Search from 'components/lib/Search'
import { orderBy } from 'lodash'
import StyledSelect from 'components/lib/MaterialElements/StyledSelect'
import StyledMenuItem from 'components/lib/MaterialElements/StyledMenuItem'
import { MoreHoriz, RuleOutlined, Add } from '@mui/icons-material'
import { toastError, toastSuccess } from 'components/lib/Toast'
import { Divider, Skeleton } from '@mui/material'
import {
  deleteHandoffRule,
  getHandoffRules,
} from '../../../api/assistant/handoffRule'
import HandoffRuleForm from './HandoffRuleForm'

enum SortOption {
  UpdatedAt = 'updatedAt',
  Title = 'title',
  Type = 'type',
  Association = 'association',
}

const HandoffRules = () => {
  const companyAssociations = useRecoilValue(companyAssociationsAtom)
  const [handoffRules, setHandoffRules] = useRecoilState(allHandoffRulesAtom)
  const [handoffRuleForm, setHandoffRuleForm] =
    useRecoilState(handoffRuleFormAtom)
  const [isLoading, setIsLoading] = useState(true)
  const [searchText, setSearchText] = useState('')
  const [sortKey, setSortKey] = useState(SortOption.UpdatedAt)
  const [sortOrder, setSortOrder] = useState<'asc' | 'desc'>('desc')
  const { selectedContact } = useRecoilValue(authenticatedUserAtom)

  useEffect(() => {
    const fetchHandoffRules = async () => {
      try {
        setIsLoading(true)
        const rules = await getHandoffRules(selectedContact.companyId)
        setHandoffRules(rules)
      } catch (error) {
        toastError('Failed to load handoff rules')
      } finally {
        setIsLoading(false)
      }
    }

    fetchHandoffRules()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedContact.companyId])

  const handleSort = (nextSort: SortOption) => {
    if (nextSort === sortKey) {
      setSortOrder(sortOrder === 'asc' ? 'desc' : 'asc')
    } else {
      setSortKey(nextSort)
      setSortOrder('asc')
    }
  }

  const filteredHandoffRules = useMemo(
    () =>
      (handoffRules || []).filter(rule =>
        rule.title.toLowerCase().includes(searchText.toLowerCase()),
      ),
    [searchText, handoffRules],
  )

  const sortedHandoffRules = useMemo(() => {
    if (sortKey === SortOption.UpdatedAt) {
      return orderBy(
        filteredHandoffRules,
        rule => (rule.updatedAt ? rule.updatedAt : rule.createdAt),
        sortOrder,
      )
    }

    if (sortKey === SortOption.Title) {
      return orderBy(filteredHandoffRules, 'title', sortOrder)
    }

    if (sortKey === SortOption.Type) {
      return orderBy(filteredHandoffRules, 'type', sortOrder)
    }
    if (sortKey === SortOption.Association) {
      return orderBy(
        filteredHandoffRules,
        rule => rule.associationIds.join(','),
        sortOrder,
      )
    }
    return filteredHandoffRules
  }, [sortKey, sortOrder, filteredHandoffRules])

  if (handoffRuleForm.isOpen) {
    return <HandoffRuleForm />
  }

  return (
    <div style={{ marginTop: -40 }}>
      <FlexRow align="center" justify="flex-end">
        <FlexRow>
          <TextButton
            onClick={() => {
              setHandoffRuleForm({
                handoffRule: {
                  title: '',
                  communicationType: [HandoffCommunicationType.Voice],
                  description: '',
                },
                isOpen: true,
              })
            }}
          >
            <Add style={{ fontSize: 18 }} />
            <h4 style={{ fontWeight: 500 }}>Add</h4>
          </TextButton>
        </FlexRow>
        <Search
          text={searchText}
          changeText={setSearchText}
          placeholder="Search handoff rules"
        />
      </FlexRow>

      <TableWrapper>
        <Table>
          <TableHead>
            <TableRow style={{ width: '100%' }}>
              <SortableTableHeaderCell
                active={sortKey === SortOption.Title}
                sortOrder={sortOrder}
                onClick={() => handleSort(SortOption.Title)}
                style={{
                  textAlign: 'left',
                  width: '70%',
                  border: 'none',
                }}
              >
                Title
              </SortableTableHeaderCell>

              <SortableTableHeaderCell
                active={sortKey === SortOption.Type}
                sortOrder={sortOrder}
                onClick={() => handleSort(SortOption.Type)}
                style={{
                  textAlign: 'left',
                  width: '20%',
                  border: 'none',
                  outline: 'none',
                }}
              >
                Type
              </SortableTableHeaderCell>

              <SortableTableHeaderCell
                active={sortKey === SortOption.Association}
                sortOrder={sortOrder}
                onClick={() => handleSort(SortOption.Association)}
                style={{
                  textAlign: 'left',
                  width: '20%',
                  border: 'none',
                  outline: 'none',
                }}
              >
                Accounts
              </SortableTableHeaderCell>
              <TableHeaderCell
                style={{
                  textAlign: 'left',
                  width: '5%',
                  border: 'none',
                  outline: 'none',
                }}
              />
            </TableRow>
          </TableHead>
          <TableBody>
            {isLoading ? (
              <>
                {[...Array(3)].map((_, i) => (
                  // eslint-disable-next-line react/no-array-index-key
                  <TableRow key={`loading-skeleton-${i}`}>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                    <TableCell>
                      <Skeleton variant="text" />
                    </TableCell>
                  </TableRow>
                ))}
              </>
            ) : sortedHandoffRules && sortedHandoffRules.length > 0 ? (
              sortedHandoffRules.map(rule => (
                <TableRow key={rule.id}>
                  <TableCell
                    style={{
                      textTransform: 'none',
                      borderRight: '1px solid #E1E4E8',
                      paddingLeft: 10,
                    }}
                  >
                    <RuleOutlined style={{ marginRight: 12, fontSize: 18 }} />
                    {rule.title}
                  </TableCell>

                  <TableCell
                    style={{
                      textTransform: 'none',
                      borderRight: '1px solid #E1E4E8',
                      paddingLeft: 10,
                    }}
                  >
                    {rule.communicationType?.join(', ')}
                  </TableCell>

                  <TableCell
                    style={{
                      textTransform: 'none',
                      borderRight: '1px solid #E1E4E8',
                      paddingLeft: 10,
                    }}
                  >
                    {rule.associationIds.length === 0
                      ? 'Company-wide'
                      : rule.associationIds.length === 1
                      ? companyAssociations.associations.find(
                          a => a.id === rule.associationIds[0],
                        )?.name
                      : `${rule.associationIds.length} properties`}
                  </TableCell>
                  <TableCell style={{ textTransform: 'none' }}>
                    <StyledSelect
                      renderValue={() => (
                        <IconButton>
                          <MoreHoriz />
                        </IconButton>
                      )}
                      defaultValue=""
                    >
                      <StyledMenuItem
                        onClick={() => {
                          setHandoffRuleForm({
                            handoffRule: rule,
                            isOpen: true,
                          })
                        }}
                      >
                        Edit
                      </StyledMenuItem>

                      <StyledMenuItem
                        onClick={() => {
                          setHandoffRuleForm({
                            handoffRule: {
                              title: rule.title,
                              communicationType: rule.communicationType,
                              associationIds: rule.associationIds,
                              description: rule.description,
                            },
                            isOpen: true,
                          })
                        }}
                      >
                        Duplicate
                      </StyledMenuItem>
                      <Divider />
                      <StyledMenuItem
                        style={{ color: '#d62323' }}
                        onClick={() => {
                          deleteHandoffRule(rule.id, selectedContact.companyId)
                            .then(() => {
                              toastSuccess('Handoff rule deleted')
                              setHandoffRules(prev =>
                                prev.filter(r => r.id !== rule.id),
                              )
                            })
                            .catch(() => {
                              toastError('Failed to delete handoff rule')
                            })
                        }}
                      >
                        Delete
                      </StyledMenuItem>
                    </StyledSelect>
                  </TableCell>
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={4}
                  style={{
                    textTransform: 'none',
                    textAlign: 'left',
                    fontWeight: 500,
                    color: '#627088',
                  }}
                >
                  No handoff rules to display.{' '}
                  <span
                    style={{ textDecoration: 'underline', cursor: 'pointer' }}
                    onClick={() => {
                      setHandoffRuleForm({
                        handoffRule: {
                          title: '',
                          communicationType: [HandoffCommunicationType.Voice],
                          associationIds: [],
                          description: '',
                        },
                        isOpen: true,
                      })
                    }}
                  >
                    Add a handoff rule
                  </span>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableWrapper>
    </div>
  )
}

export default HandoffRules
