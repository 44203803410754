import {
  AccessLevel,
  APIContact,
  Contact,
  ContactGroup,
  ContactSecrets,
  SearchableContact,
  shapeSearchableContact,
} from '@super-software-inc/foundation'
import searchTypesense, { SearchTypesenseParams } from 'api/typesense'
import { getFunctions, httpsCallable, HttpsCallable } from 'firebase/functions'
import { contactsCacheAtom } from 'hooks/useContactsCache'
import { getRecoil, setRecoil } from 'recoil-nexus'
import { authenticatedUserAtom } from 'state/atoms'

interface GetContactsFromTypesenseRes {
  contacts: SearchableContact[]
  totalContacts: number
}

export interface GetContactsFromTypesenseProps {
  q?: string
  page?: number
  pageSize?: number
  associationIds?: string[]
  groups?: ContactGroup[]
  companyId: string
  sortByStr?: string
  includeFields?: string[]
  excludeFields?: string[]
  exhaustiveSearch?: boolean
}

export async function getContactsFromTypesense(
  {
    q = '*',
    page,
    pageSize = 40,
    associationIds,
    groups,
    companyId,
    sortByStr,
    exhaustiveSearch,
  }: GetContactsFromTypesenseProps,
  contactSecrets: ContactSecrets | undefined = undefined,
): Promise<GetContactsFromTypesenseRes> {
  let filterBy = `companyId:${companyId}`

  if (associationIds) {
    filterBy += `&& associationIds: [${associationIds.join()}]`
  }

  if (groups) {
    filterBy += `&& groups: [${groups.join()}]`
  }

  if (!contactSecrets) {
    return {
      contacts: [],
      totalContacts: 0,
    }
  }

  const [results] = await searchTypesense(contactSecrets, [
    {
      collection: 'searchableContacts',
      filterBy,
      page: page || 40,
      pageSize,
      query: q,
      queryBy: 'firstName,lastName,email,phone.number',
      sortBy: sortByStr,
      exhaustiveSearch,
    },
  ])

  return {
    contacts: results.documents as SearchableContact[],
    totalContacts: results.totalDocuments,
  }
}

export const getContactFromTypesense = async (
  contactId: string,
  contactSecrets: ContactSecrets | undefined = undefined,
): Promise<SearchableContact | null> => {
  if (!contactSecrets) {
    return null
  }

  const [results] = await searchTypesense(contactSecrets, [
    {
      collection: 'searchableContacts',
      filterBy: `id:${contactId}`,
      pageSize: 1,
    },
  ])

  return results.documents[0] as SearchableContact | null
}

export const getContactGroupCountsFromTypesense = async (
  contactSecrets: ContactSecrets | undefined = undefined,
) => {
  const groups = Object.values(ContactGroup)

  const searches: SearchTypesenseParams[] = groups.map(group => ({
    collection: 'searchableContacts',
    filterBy: `groups: [${group}]`,
    pageSize: 0, // only get counts
    query: '*',
  }))

  // Upon secrets being undefined, return 0 counts for all groups
  if (!contactSecrets) {
    return groups.map(group => ({
      group,
      count: 0,
    }))
  }

  const results = await searchTypesense(contactSecrets, searches)

  return results.map(({ documents }, i) => ({
    group: groups[i],
    count: documents.length,
  }))
}

export const getAllContactsFromTypesense = async (
  params: GetContactsFromTypesenseProps,
  contactSecrets: ContactSecrets | undefined = undefined,
): Promise<SearchableContact[]> => {
  if (!contactSecrets) {
    return []
  }

  // Do an initial request to Typesense to get the total number of contacts:
  const [searchRes] = await searchTypesense(contactSecrets, [
    {
      collection: 'searchableContacts',
      query: '*',
      pageSize: 0,
      filterBy: `companyId:${params.companyId}`,
    },
  ])

  const totalContacts = searchRes.totalDocuments

  if (!totalContacts) {
    // If there aren't any contacts to get, exit early:
    return []
  }

  // Now, make a series of requests to get all contacts with the maximum page size (250) in parallel:
  const numberOfPages = Math.ceil(totalContacts / 250)

  const {
    companyId,
    associationIds,
    groups,
    includeFields,
    excludeFields,
    page, // supports the case where want to "start" from a certain page, aka "sneaky pagination"
  } = params

  let filterBy = `companyId:${companyId}`

  if (associationIds) {
    filterBy += `&& associationIds: [${associationIds.join()}]`
  }

  if (groups) {
    filterBy += `&& groups: [${groups.join()}]`
  }

  // Also exclude contactIdsCanAccess, as we don't use this in the FE
  // and it takes up a lot of space in the response!

  const searchParams: SearchTypesenseParams[] = Array.from(
    { length: numberOfPages },
    (_, i) => ({
      collection: 'searchableContacts',
      query: '*',
      page: i + (page || 0),
      pageSize: 250,
      filterBy,
      ...(excludeFields && { excludeFields }),
      ...(includeFields && { includeFields }),
    }),
  )

  const results = await searchTypesense(contactSecrets, searchParams)

  return results.flatMap(({ documents }) => documents as SearchableContact[])
}

export const updateContactsCache = (newContact: SearchableContact) => {
  // use recoil nexus to update contactsCache
  const contactsCache = getRecoil(contactsCacheAtom)

  // if contact exists in cache, update it. otherwise, add it
  const newContactsCache = contactsCache.find(
    contact => contact.id === newContact.id,
  )
    ? contactsCache.map(contact =>
        contact.id === newContact.id ? newContact : contact,
      )
    : [...contactsCache, newContact]

  setRecoil(contactsCacheAtom, newContactsCache)
}

// TODO: #corpsV2 make this take an entire contact
export const addContact = async (
  companyId: string,
  associationId: string,
  email: string | null,
  phoneNumber: string | null,
  group: ContactGroup,
  ignoreDupeError: boolean = false,
) => {
  // use recoil nexus to get the authenticated user
  const authenticatedUser = getRecoil(authenticatedUserAtom)

  if (!email && !phoneNumber) {
    throw new Error('Email or phone required')
  }

  const functions = getFunctions()
  functions.region = 'us-east1'

  const createContact: HttpsCallable<
    { companyId: string; contact: Partial<Contact>; ignoreDupeError?: boolean },
    APIContact
  > = httpsCallable(functions, 'createContact')

  const { data } = await createContact({
    companyId,
    contact: {
      ...(email && { email }),
      ...(phoneNumber && {
        phone: {
          number: phoneNumber,
          type: 'mobile',
        },
      }),
      propertyInfo: [
        {
          associationId,
          groups: [group],
          units: [],
          actId:
            authenticatedUser.acts.find(
              act => act.name === AccessLevel.NoAccess,
            )?.id || '',
          accessLevel: AccessLevel.NoAccess,
          propertyRelation: null,
          boardTerms: [],
          title: '',
        },
      ],
    },
    ignoreDupeError,
  })

  // Convert to SearchableContact:
  const searchableContact = shapeSearchableContact(
    data.id,
    data,
    [authenticatedUser.selectedContact.id], // authenticated user can see this contact -> this is only the case locally (the trigger function remotely will populate the array completely)
    companyId,
    authenticatedUser.selectedCompany.name,
  )

  // add new contact to contactsCache
  updateContactsCache(searchableContact)

  return data
}

export const updateContact = async (contact: APIContact) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  // use recoil nexus to get the authenticated user
  const authenticatedUser = getRecoil(authenticatedUserAtom)

  const updateContactCallable: HttpsCallable<
    {
      companyId: string
      contactId: string
      contact: Contact
    },
    APIContact
  > = httpsCallable(functions, 'updateContact')

  const { data } = await updateContactCallable({
    companyId: contact.companyId,
    contactId: contact.id,
    contact,
  })

  // Convert to SearchableContact:
  const searchableContact = shapeSearchableContact(
    data.id,
    data,
    [authenticatedUser.selectedContact.id],
    contact.companyId,
    authenticatedUser.selectedCompany.name,
  )

  // update contact in contactsCache
  updateContactsCache(searchableContact)

  return data
}

export const getContactByContactId = async (
  companyId: string,
  contactId: string,
) => {
  const functions = getFunctions()
  functions.region = 'us-east1'

  const getContact: HttpsCallable<
    { companyId: string; contactId: string },
    APIContact
  > = httpsCallable(functions, 'getContactByContactId')

  const { data } = await getContact({ companyId, contactId })

  // convert to APIContact
  const contact = {
    ...data,
    id: contactId,
  }

  return contact
}
