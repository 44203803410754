import { APIHandoffRule } from '@super-software-inc/foundation'
import { getFunctions, httpsCallable, HttpsCallable } from 'firebase/functions'

export const getHandoffRules = async (
  companyId: string,
): Promise<APIHandoffRule[]> => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const getHandoffRulesAPI: HttpsCallable<
    { companyId: string },
    APIHandoffRule[]
  > = httpsCallable(functions, 'getHandoffRules')

  const handoffRules = await getHandoffRulesAPI({ companyId })
  return handoffRules.data
}

export const createHandoffRule = async (
  companyId: string,
  handoffRule: Partial<APIHandoffRule>,
): Promise<APIHandoffRule> => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const createHandoffRuleAPI: HttpsCallable<
    { companyId: string; handoffRule: Partial<APIHandoffRule> },
    APIHandoffRule
  > = httpsCallable(functions, 'createHandoffRule')

  const rule = await createHandoffRuleAPI({
    companyId,
    handoffRule,
  })

  return rule.data
}

export const updateHandoffRule = async (
  handoffRuleId: string,
  companyId: string,
  handoffRule: Partial<APIHandoffRule>,
): Promise<APIHandoffRule> => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const updateHandoffRuleAPI: HttpsCallable<
    {
      companyId: string
      handoffRuleId: string
      handoffRule: Partial<APIHandoffRule>
    },
    APIHandoffRule
  > = httpsCallable(functions, 'updateHandoffRule')

  const rule = await updateHandoffRuleAPI({
    companyId,
    handoffRuleId,
    handoffRule,
  })

  return rule.data
}

export const deleteHandoffRule = async (
  handoffRuleId: string,
  companyId: string,
): Promise<void> => {
  const functions = getFunctions()
  functions.region = 'us-east1'
  const deleteHandoffRuleAPI: HttpsCallable<
    { handoffRuleId: string; companyId: string },
    void
  > = httpsCallable(functions, 'deleteHandoffRule')

  await deleteHandoffRuleAPI({ handoffRuleId, companyId })
}
